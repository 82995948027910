import {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {ItemTypes} from './ItemTypes';

export const Tile = ({id, text, index, img, moveTile, url}) => {
    const ref = useRef(null);
    const [{handlerId}, drop] = useDrop({
        accept: ItemTypes.TILE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveTile(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.TILE,
        item: () => {
            return {id, index};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.3 : 0.8;
    drag(drop(ref));
    return (
        <div ref={ref} style={{...opacity}} data-handler-id={handlerId} data-se="app-card-container" className="tile"
             draggable="true">
            <a className="tile-a"
               href={url}
               target="_blank" rel="noopener noreferrer">
                <article className="chiclet--article">
                    <section className="chiclet--main" data-se="app-card-main"><img className="app-logo--image"
                                                                                    src={img}
                                                                                    alt="Slack logo"/></section>
                    <footer className="chiclet--footer" data-se="app-card-footer">
                        {text}
                    </footer>
                </article>
            </a>
            <button className="chiclet--action" tabIndex="0" aria-label="Settings for Slack"
                    data-se="app-card-settings-button">
                <svg className="chiclet--action-kebab" width="20" height="4" viewBox="0 0 20 4" fill="#B7BCC0"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2"/>
                    <circle cx="10" cy="2" r="2"/>
                    <circle cx="18" cy="2" r="2"/>
                </svg>
            </button>
        </div>
    )
};
