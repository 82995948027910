import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Containers} from "./containers";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {TopMenu} from "./topMenu";
import {Side} from "./Side";
import {Props} from "./Props";


const iconPath = process.env.PUBLIC_URL + '/assets/images/';

const App = () => {
    const {isAuthenticated, isLoading, error, loginWithRedirect} = useAuth0();
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    });
    if (error !== undefined) {
        return <span>Oups... {error}</span>
    }
    if (isLoading) {
        return <span>Is loading</span>;
    }

    if (!isAuthenticated) {
        return <span>Please wait...</span>
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="content-frame">
                <TopMenu/>
                <Side/><Containers/><Props/>
            </div>
        </DndProvider>

    );
};

export default App;

const app = <App/>;

ReactDOM.render(
    <Auth0Provider
        domain="metapack-dev.eu.auth0.com"
        clientId="PQkJSRSS5v4JgvnVzKHYqSl0FmmTznYy"
        redirectUri={window.location.origin}
        organization="org_d0yZK8KocXOkfZZB"
    >
        <App/>
    </Auth0Provider>,
    document.getElementById("root")
);


