import {useState, useCallback} from 'react';
import update from 'immutability-helper';
import {Tile} from "./tile";

export const Container = (items) => {

    const [tiles, setTiles] = useState(items.items);
    const moveTile = useCallback((dragIndex, hoverIndex) => {
        const dragTile = tiles[dragIndex];
        setTiles(update(tiles, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragTile],
            ],
        }));
    }, [tiles]);

    const renderTile = (tile, index) => {
        return (
            <Tile key={tile.id} index={index} id={tile.id} img={tile.img} text={tile.text} url={tile.url}
                  moveTile={moveTile}/>
        );
    };

    const addTiles = (hidden) => {
        if (!hidden) {
            return (
                <section className="tiles">
                    {tiles.map((tile, i) => renderTile(tile, i))}
                </section>
            )
        }
        return (<section className="tiles"/>)
    }

    const [it, its] = useState(items.hidden);
    const handleClick = () => {
        its(!it)
    }

    const transform = (hid) => {
        return hid ? "rotate(180 8 8)" : ""
    };

    return (
        <div className="main-container">
            <header className="container-header">
                <button className="container-header-button" onClick={handleClick}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.54309 7.23955C7.79018 6.97481 8.20982 6.97481 8.45691 7.23955L11.0431 10.0105C11.2902 10.2752 11.7098 10.2752 11.9569 10.0105L11.977 9.98895C12.2011 9.74882 12.2011 9.37618 11.977 9.13605L8.46009 5.36795C8.21183 5.10196 7.78974 5.10342 7.54333 5.37111L4.02817 9.18988C3.80296 9.43454 3.81111 9.81337 4.04663 10.0481V10.0481C4.29458 10.2953 4.6979 10.288 4.93676 10.032L7.54309 7.23955Z"
                              fill="#212126" transform={transform(it)}/>
                        <circle cx="8" cy="8" r="7.25" stroke="#212126" stroke-width="1.5"></circle>
                    </svg>
                    <span className="container-header-text">{items.title}</span>
                </button>
            </header>
            {addTiles(it)}
        </div>
    );
};
